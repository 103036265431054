import {defineStore} from 'pinia';
import {ref, useFeatureFlag} from '#imports';
import type {FeatureContext} from '~/store/featureTypes';
import {FeatureFlags} from '~/store/featureTypes';

export const useFeatureContext = defineStore('features', () => {
    const features = ref<FeatureContext>({} as FeatureContext);
    const {fetchFeatureFlags} = useFeatureFlag();

    async function loadFeatureContext(): Promise<void> {
        features.value = await fetchFeatureFlags([...FeatureFlags]);
    }

    return {
        features,
        loadFeatureContext,
    };
});
