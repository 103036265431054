export const FeatureFlags = [
    'malt-start-links',
    'missing-description-visible',
    'modify-freelancer-newsletter-opt-logic-depending-on-host',
    'new-linkedin-import-choice-step',
    'activate-profile-session-replay',
    'profile-view-blocker',
] as const;

export type FeatureFlag = (typeof FeatureFlags)[number];

export type FeatureContext = Record<FeatureFlag, boolean | undefined>;
